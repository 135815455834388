.App {
  text-align: center;
  margin-top: 250px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.event {
  border: 1px solid black;
  max-width: 350px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 15px;
  padding: 15px;
}

.author-image {
  width: 60px;
  border-radius: 100px;
}